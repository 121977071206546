import { Fragment } from "react"
import { css } from "../ui/helpers/css"
import { Image, Uuid } from "../../../../reactor"
import { Section } from "../../../../packages/editing/Section"
import { useWebPageInfo } from "../../../../studio/client"
import { useLocalizeSlug } from "../../../../packages/web/hooks/useLocalizeSlug"
import { Page } from "../../model/Page"
import { Localized } from "../../../../packages/localization/Localized"
import { ProductCard } from "../ui/components/cards/ProductCard"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Heading } from "../ui/components/typography/Heading"
import { Box } from "../ui/components/base/Box"
import { Flex } from "../ui/components/base/Flex"
import { responsiveCss, responsiveSectionSpacing, responsiveSpacing } from "../ui/helpers/css"
import { Color } from "../ui/constants/colors"
import { SeeAllCard } from "../ui/components/cards/SeeAllCard"
import { usePhoneModels, usePhoneOffers } from "../client"
import { PhoneModel } from "../../model/PhoneModel"
import { monthsShort, useFormatAmount } from "../ui/constants/text"
import { LocaleKey } from "../../../../packages/localization/Locale"
import { ProductDetailsSearchParams } from "./ProductDetails"

export type PromotedProductSeeAll = {
    /**
     * @default '{"no": "Se hele vårt utvalg"}'
     */
    heading: Localized<string>

    /**
     * Call to action button on "see all" section.
     */
    cta: {
        /**
         * Text for the CTA button.
         * @default '{"no": "Vis alt"}'
         */
        text: Localized<string>

        /**
         * The page with all the products.
         */
        page: Page["id"]
    }

    /**
     * Image to show at the bottom of the block.
     */
    image?: Image
}

export type PromotedProductCta = {
    /**
     * Text for the CTA button.
     * @default '{"no": "Bestill nå"}'
     */
    text: Localized<string>

    /**
     * The page to open when the CTA button is clicked.
     */
    page: Page["id"]

    /**
     * Always show the CTA button on the product card, not just on hover, which is the default
     * behavior.
     */
    alwaysShow?: boolean
}

/**
 * Same as PromotedProducts, presenting 4 promoted products from the product catalogue, but with
 * the products automatically selected based on some criteria.
 */
function PromotedProductsAuto(section: {
    /**
     * The heading for this section
     */
    heading: Localized<string>

    /**
     * The phone models to get offers for. Providing no phones still returns phone offers, but
     * we don't control which ones.
     * @expand
     */
    phoneModels?: PhoneModel["id"][]

    /**
     * The call to action button on each product card. URL should point to general product page
     * and then the product id will be passed as parameter.
     */
    productCta: PromotedProductCta

    /**
     * See all block with a photo and a link to all products.
     */
    seeAll: PromotedProductSeeAll
}) {
    const offers = usePhoneOffers(section.phoneModels, undefined, undefined, undefined)
    const localize = useLocalize()
    const formatAmount = useFormatAmount()
    const phoneModels = usePhoneModels(section.phoneModels ?? null)

    if (!offers.data || !phoneModels.data) return <></>

    return (
        <PromotedProducts
            {...section}
            products={phoneModels.data.map((pm) => {
                const offer = offers.data?.find((o) => o.model === pm.id)

                if (!offer) {
                    return {
                        phoneModelId: pm.id,
                        name: pm.name,
                        features: pm.features?.map((f) => f.text) ?? [],
                        price: { no: "Utsolgt", en: "Sold out" },
                        image: pm.images?.[0],
                        relativePhysicalSize: pm.relativePhysicalSize,
                        soldOut: true,
                    }
                }
                return {
                    phoneModelId: pm.id,
                    phoneId: offer.id,
                    name: offer.name,
                    features: offer.features?.map((f) => f.text) ?? [],
                    price: {
                        no: `Fra ${formatAmount(offer.monthlyPrice.itemsTotal)}/${localize(monthsShort, LocaleKey.no)}`,
                        en: `From ${formatAmount(offer.monthlyPrice.itemsTotal)}/${localize(monthsShort, LocaleKey.en)}`,
                    },
                    image: offer.images?.[0],
                    relativePhysicalSize: offer.relativePhysicalSize,
                }
            })}
        />
    )
}
Section(PromotedProductsAuto)

/**
 * Section presenting 4 promoted products from the product catalogue. Renders as a grid on desktop
 * and list on mobile.
 */
function PromotedProducts(section: {
    /**
     * The heading for this section.
     * @default '{"no": "Våre enheter"}'
     */
    heading: Localized<string>

    /**
     * The call to action button on each product card. URL should point to general product page
     * and then the product id will be passed as parameter.
     */
    productCta: PromotedProductCta

    /**
     * See all block with a photo and a link to all products.
     */
    seeAll: PromotedProductSeeAll

    /**
     * The products to show.
     *
     * @expand
     */
    products: {
        phoneId?: Uuid<"Phone">
        phoneModelId: Uuid<"PhoneModel">
        name: Localized<string>
        features: Localized<string>[]
        price: Localized<string>
        image?: Image

        relativePhysicalSize?: number

        /**
         * Toggle on if price should be highlighted in brand color.
         */
        highlightPrice?: boolean

        /**
         * Show a label to bring more attention to the product, e.g. "new" or "deal".
         *
         * @expand
         */
        label?: {
            text: Localized<string>
            color: Color
        }
        soldOut?: boolean
    }[]

    /**
     * A generic fallback image to show on a product card if the product does not have an image.
     */
    fallbackProductImage?: Image
}) {
    const localize = useLocalize()
    // NOTE: Apparently, judging from all the console errors, these can be undefined.
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const productCtaPageInfo = useWebPageInfo(section.productCta.page ?? null)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const seeAllCtaPageInfo = useWebPageInfo(section.seeAll.cta.page ?? null)
    const localizeSlug = useLocalizeSlug()

    if (!productCtaPageInfo.data?.slug) return <></>

    return (
        <Flex direction="column" css={css(responsiveSectionSpacing())}>
            <div css={css({ marginBottom: 16 }, responsiveCss("min", "md", { marginBottom: 24 }))}>
                <Heading level={2}>{localize(section.heading)}</Heading>
            </div>
            <Box
                css={css(
                    {
                        display: "grid",
                        gridAutoFlow: "row",
                        gridTemplateRows: "1fr",
                    },
                    responsiveCss("min", "md", {
                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                        gridTemplateAreas: '"C1 C2 C3" "C4 AA AA"',
                        gridTemplateRows: "1fr 1fr",
                    }),
                    responsiveSpacing("sm", "gap")
                    // Increased gap for mobile with a different layout.
                )}
            >
                {section.products.slice(0, 4).map((p, i) => (
                    <Fragment key={p.phoneModelId.valueOf()}>
                        <Box
                            css={css(
                                responsiveCss("max", "sm", {
                                    display: "none",
                                }),
                                responsiveCss("min", "md", {
                                    gridArea: `C${i + 1}`,
                                })
                            )}
                        >
                            <ProductCard
                                disabled={p.soldOut}
                                alwaysShowCta={section.productCta.alwaysShow}
                                name={localize(p.name)}
                                features={p.features.map((f) => localize(f))}
                                price={localize(p.price)}
                                highlightPrice={p.highlightPrice}
                                image={p.image}
                                cta={
                                    p.soldOut || !p.phoneId
                                        ? undefined
                                        : {
                                              text: localize(section.productCta.text),
                                              url: `${localizeSlug(productCtaPageInfo.data?.slug)}?${ProductDetailsSearchParams({ phoneId: p.phoneId })}`,
                                          }
                                }
                                relativePhysicalSize={p.relativePhysicalSize?.valueOf()}
                                label={
                                    p.label
                                        ? {
                                              text: localize(p.label.text),
                                              color: p.label.color,
                                          }
                                        : undefined
                                }
                            />
                        </Box>
                        <Flex
                            direction="column"
                            gap={16}
                            css={responsiveCss("min", "md", {
                                display: "none",
                            })}
                        >
                            <ProductCard
                                disabled={p.soldOut}
                                name={localize(p.name)}
                                features={p.features.map((f) => localize(f))}
                                price={localize(p.price)}
                                image={p.image}
                                highlightPrice={p.highlightPrice}
                                relativePhysicalSize={p.relativePhysicalSize?.valueOf()}
                                label={
                                    p.label
                                        ? {
                                              text: localize(p.label.text),
                                              color: p.label.color,
                                          }
                                        : undefined
                                }
                                cta={
                                    p.soldOut || !p.phoneId
                                        ? undefined
                                        : {
                                              text: localize(section.productCta.text),
                                              url: `${localizeSlug(productCtaPageInfo.data?.slug)}?${ProductDetailsSearchParams({ phoneId: p.phoneId })}`,
                                          }
                                }
                                compact
                            />
                        </Flex>
                    </Fragment>
                ))}
                <Box
                    css={css(
                        responsiveCss("max", "xs", { height: 280 }),
                        responsiveCss("min", "sm", { minHeight: 372 }),
                        responsiveCss("min", "md", { gridArea: "AA" })
                    )}
                >
                    <SeeAllCard
                        heading={localize(section.seeAll.heading)}
                        cta={{
                            text: localize(section.seeAll.cta.text),
                            url: localizeSlug(seeAllCtaPageInfo.data?.slug),
                        }}
                        image={section.seeAll.image}
                    />
                </Box>
            </Box>
        </Flex>
    )
}

Section(PromotedProducts)
