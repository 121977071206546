import { css } from "@emotion/react"
import dayjs from "dayjs"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { Section } from "../../../../packages/editing/Section"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { useLogOutWithVipps } from "../../../../packages/oidc/code-flow/VippsLogin"
import { Page } from "../../model/Page"
import {
    putPreferredStoredPaymentMethod,
    useMe,
    usePhoneOrders,
    useStoredPaymentMethods,
} from "../client"
import { Flex } from "../ui/components/base/Flex"
import { ResponsiveImage } from "../ui/components/base/ResponsiveImage"
import { Button } from "../ui/components/buttons/Button"
import { Divider } from "../ui/components/other/Divider"
import { Heading } from "../ui/components/typography/Heading"
import { Text } from "../ui/components/typography/Text"
import { Fragment, useCallback, useState } from "react"
import { Modal } from "../ui/components/modal/Modal"
import { MarkdownView } from "../ui/components/base/MarkdownView"
import { Markdown, Uuid } from "../../../../reactor"
import { responsiveCss, scaleValue } from "../ui/helpers/css"
import { Icon } from "../ui/components/visual/Icon"
import { useWebPageInfo } from "../../../../studio/client"
import { monthsShort } from "../ui/constants/text"
import { colors } from "../ui/constants/colors"
import { AdyenComponent } from "../../../../packages/adyen/web/AdyenComponent"
import { useSessionState } from "../../../../reactor/Web"
import { adyenPaymentFormCss } from "./checkout/PaymentStep"
import { LocaleKey } from "../../../../packages/localization/Locale"
import { LabelNotification } from "../ui/components/notification/LabelNotification"
import { Notification } from "../ui/components/Notification"
import { Box } from "../ui/components/base/Box"

Section(AccountOverview)
function AccountOverview(section: {
    /**
     * Account dashboard headline. Available variables are `{{givenName}}` and `{{familyName}}`.
     * @default '{"no": "Kontooversikt"}'
     */
    heading: Localized<string>

    /**
     * Text below the headline. Available variables are `{{givenName}}` and `{{familyName}}`.
     * @default '{"no": "Hei {{givenName}}. Her finner du oversikten over dine abonnementer."}'
     */
    subheading: Localized<string>

    activeSubscriptions: ActiveSubscriptionsProps
    paymentMethods: PaymentMethodsProps
}) {
    const me = useMe()
    const localize = useLocalize()
    const logOutWithVipps = useLogOutWithVipps()

    dayjs.extend(advancedFormat)

    return (
        <Flex
            justifyContent="center"
            css={css(
                { paddingTop: 32, paddingBottom: 32 },
                responsiveCss("min", "md", { paddingTop: 64, paddingBottom: 64 })
            )}
        >
            <div
                style={{ width: "100%" }}
                css={css(
                    {
                        display: "grid",
                        gridTemplateAreas: '"text details" "menu details"',
                        gridTemplateColumns: "56fr 70fr",
                        gridTemplateRows: "minmax(0, auto)",
                        columnGap: scaleValue(140),
                    },
                    responsiveCss("max", "sm", {
                        gridTemplateAreas: '"text" "details" "menu"',
                        gridTemplateRows: "auto",
                        gridTemplateColumns: "1fr",
                        rowGap: 32,
                    }),
                    responsiveCss("min", "lg", { paddingLeft: 96, paddingRight: 96 })
                )}
            >
                <Flex direction="column" gap={16} style={{ gridArea: "text", minWidth: 0 }}>
                    <Heading level={1}>
                        {MustacheString(localize(section.heading), {
                            givenName: me.data?.givenName ?? "",
                            familyName: me.data?.familyName ?? "",
                        })}
                    </Heading>
                    <Text variant="body" size="lg" color="gray400">
                        {MustacheString(localize(section.subheading), {
                            givenName: me.data?.givenName ?? "",
                            familyName: me.data?.familyName ?? "",
                        })}
                    </Text>
                </Flex>
                <Flex
                    gap={28}
                    direction="column"
                    margin={{ top: scaleValue(48) }}
                    style={{ gridArea: "menu", minWidth: 0 }}
                >
                    <Flex
                        style={{ cursor: "pointer" }}
                        justifyContent="space-between"
                        alignItems="center"
                        onClick={async () => {
                            await logOutWithVipps()
                            window.location.href = "/"
                        }}
                    >
                        <Text variant="heading" level="3">
                            {localize({ no: "Logg ut", en: "Sign out" })}
                        </Text>
                        <Icon icon="arrowRight" />
                    </Flex>
                </Flex>
                <Flex direction="column" style={{ gridArea: "details", minWidth: 0 }}>
                    <ActiveSubscriptions {...section.activeSubscriptions} />
                    <PaymentMethods {...section.paymentMethods} />
                </Flex>
            </div>
        </Flex>
    )
}

type ActiveSubscriptionsProps = {
    /**
     * @default '{"no": "Dine abonnementer"}'
     */
    heading: Localized<string>

    /**
     * The subscription details page.
     */
    detailsPage: Page["id"]

    /**
     * @default '{"no": "Kansellert", "en": "Cancelled"}'
     */
    cancelledLabelText: Localized<string>

    /**
     * @default '{"no": "Se detaljer", "en": "See details"}'
     */
    detailsButtonText: Localized<string>
}

function ActiveSubscriptions(props: ActiveSubscriptionsProps) {
    const orders = usePhoneOrders()
    const localize = useLocalize()
    const [descriptionModalIsOpen, setDescriptionModalIsOpen] = useState<
        Uuid<"PhoneOrder"> | undefined
    >()

    const detailsPageInfo = useWebPageInfo(props.detailsPage)

    if (!orders.data?.orders.length) return <></>

    return (
        <>
            <Heading level={3} margin={{ bottom: scaleValue(24) }}>
                {localize(props.heading)}
            </Heading>
            <Flex gap={scaleValue(24)} direction="column">
                {orders.data.orders.map((order) => (
                    <Flex
                        key={order.id.valueOf()}
                        backgroundColor="gray100"
                        as="a"
                        href={
                            detailsPageInfo.data?.slug
                                ? `/${localize(detailsPageInfo.data.slug)}?id=${order.id.valueOf()}`
                                : ""
                        }
                        direction="column"
                        borderRadius="md"
                    >
                        <Flex
                            css={css(
                                { padding: scaleValue(24), gap: scaleValue(16) },
                                responsiveCss("min", "md", {
                                    padding: scaleValue(40),
                                    gap: scaleValue(32),
                                })
                            )}
                        >
                            <div
                                css={css(
                                    { width: scaleValue(72), flex: "0 0 auto" },
                                    responsiveCss("min", "md", { width: scaleValue(120) })
                                )}
                            >
                                {!!order.phone.images?.[0] && (
                                    <ResponsiveImage
                                        image={order.phone.images[0]}
                                        width={120}
                                        style={{ width: "100%" }}
                                    />
                                )}
                            </div>
                            <div style={{ flexGrow: 1 }}>
                                <Flex
                                    direction="column"
                                    css={css({ gap: 16 }, responsiveCss("min", "md", { gap: 24 }))}
                                >
                                    <div
                                        css={css({
                                            display: "grid",
                                            gridTemplateAreas:
                                                order.model.features &&
                                                order.model.features.length > 0
                                                    ? '"heading button" "features button"'
                                                    : '"heading button"',
                                            gridTemplateColumns: "1fr auto",
                                            gridTemplateRows: "auto auto",
                                        })}
                                    >
                                        <Flex style={{ gridArea: "heading" }} alignItems="center">
                                            <Heading level={4}>
                                                {localize(order.model.name)}
                                            </Heading>
                                        </Flex>
                                        {order.model.features &&
                                            order.model.features.length > 0 && (
                                                <Flex
                                                    gap={8}
                                                    alignItems="center"
                                                    style={{
                                                        gridArea: "features",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <Text
                                                        variant="body"
                                                        size="sm"
                                                        color="gray300"
                                                        css={css({
                                                            display: "inline-block",
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            flex: "1 1 auto",
                                                        })}
                                                    >
                                                        {order.model.features.map(
                                                            (feature, index) => (
                                                                <Fragment
                                                                    key={feature.id.valueOf()}
                                                                >
                                                                    {index > 0 && (
                                                                        <Text
                                                                            variant="body"
                                                                            size={[
                                                                                "lg",
                                                                                ["min", "lg", "xl"],
                                                                            ]}
                                                                            color="gray300"
                                                                            css={css({
                                                                                display:
                                                                                    "inline-block",
                                                                                marginLeft: 8,
                                                                                marginRight: 8,
                                                                            })}
                                                                        >
                                                                            ·
                                                                        </Text>
                                                                    )}
                                                                    {localize(feature.text)}
                                                                </Fragment>
                                                            )
                                                        )}
                                                    </Text>
                                                </Flex>
                                            )}
                                        <Flex style={{ gridArea: "button" }} alignItems="center">
                                            <Button
                                                size={["xs", ["min", "md", "sm"]]}
                                                iconStart="chevronDown"
                                                variant="light"
                                                onClick={(e) => {
                                                    setDescriptionModalIsOpen(order.id)
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                }}
                                            />
                                        </Flex>
                                        {order.model.description && (
                                            <Modal
                                                header={{
                                                    icon: "device",
                                                    title: localize({
                                                        no: "Produktdetaljer",
                                                        en: "Product details",
                                                    }),
                                                    closeButton: true,
                                                }}
                                                isOpen={descriptionModalIsOpen === order.id}
                                                onClose={() => setDescriptionModalIsOpen(undefined)}
                                            >
                                                <Text variant="body" size="sm">
                                                    <MarkdownView
                                                        value={localize(order.model.description)}
                                                    />
                                                </Text>
                                            </Modal>
                                        )}
                                    </div>
                                    <div css={responsiveCss("max", "sm", { display: "none" })}>
                                        <Divider horizontal spacing={0} />
                                    </div>
                                    <Flex direction="column" gap={4}>
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                color: order.cancelled ? colors.gray300 : undefined,
                                            }}
                                        >
                                            <Flex
                                                alignItems="baseline"
                                                gap={4}
                                                css={
                                                    order.cancelled
                                                        ? css({
                                                              position: "relative",
                                                              ":after": {
                                                                  position: "absolute",
                                                                  content: '""',
                                                                  width: "100%",
                                                                  borderBottom: "1px solid",
                                                                  top: "56%",
                                                              },
                                                          })
                                                        : undefined
                                                }
                                            >
                                                <Text variant="heading" level="3">
                                                    {order.rentalPeriods[0]?.monthlyPrice.itemsTotal.majorUnits.valueOf()}
                                                </Text>
                                                <Text variant="body" size="sm">
                                                    {order.rentalPeriods[0]?.monthlyPrice.itemsTotal.currency.toLowerCase()}
                                                    /{localize(monthsShort)}
                                                </Text>
                                            </Flex>
                                        </div>
                                        {order.nextChargeDate ? (
                                            <Text variant="body" size="sm">
                                                {localize({
                                                    no: "Neste forfall",
                                                    en: "Lease due date",
                                                })}
                                                :{" "}
                                                {dayjs(order.nextChargeDate).format("Do MMMM YYYY")}
                                            </Text>
                                        ) : null}
                                        {order.cancelled && (
                                            <Flex gap={4} alignItems="center">
                                                <Icon icon="warning" color="brand" />
                                                <Text variant="body" size="sm" color="brand">
                                                    {localize(props.cancelledLabelText)}
                                                </Text>
                                            </Flex>
                                        )}
                                    </Flex>
                                </Flex>
                            </div>
                        </Flex>
                        <Divider horizontal spacing={0} />
                        <Flex padding={scaleValue(24)} justifyContent="flex-end">
                            <Button variant="dark" size="sm">
                                {localize(props.detailsButtonText)}
                            </Button>
                        </Flex>
                    </Flex>
                ))}
            </Flex>
        </>
    )
}

type PaymentMethodsProps = {
    /**
     * @default '{"no": "Dine betalingsmetoder"}'
     */
    heading: Localized<string>

    addPaymentMethods: {
        /**
         * @default '{"no": "Legg til betalingskort"}'
         */
        modalTriggerButtonText: Localized<string>

        /**
         * @default '{"no": "Legg til betalingskort"}'
         */
        modalTitle: Localized<string>

        /**
         * Text to show in the modal above the card details form.
         */
        modalText?: Localized<Markdown>

        /**
         * @default '{"no": "Kortholders navn", "en": "Cardholder name"}'
         */
        cardholderNamePlaceholder: Localized<string>

        /**
         * @default '{"no": "Det skjedde en feil ved lagring av kortdetaljene. Prøv igjen eller ta kontakt med oss hvis problemet vedvarer."}'
         */
        errorMessage: Localized<Markdown>
    }

    /**
     * @default '{"no": "Det er ingen betalingsmetoder knyttet til din profil. Legg "}'
     */
    noPaymentMethods: Localized<string>

    /**
     * @default '{"no": "Aktivt"}'
     */
    preferredLabel: Localized<string>

    /**
     * @default '{"no": "Sett som aktivt"}'
     */
    setAsPreferredButtonText: Localized<string>
}
function PaymentMethods(props: PaymentMethodsProps) {
    const me = useMe()
    const localize = useLocalize()
    const paymentOptions = useStoredPaymentMethods()
    const orders = usePhoneOrders()
    // An id to provide as a parameter to the add card operation.
    const [addCardId, setAddCardId] = useSessionState<string | undefined>(
        "payment-methods-add-id",
        undefined
    )

    // For storing cards already added to the profile before adding a new one, so that we can set
    // the new card as the currently active automatically.
    const [originalCardIds, setOriginalCardIds] = useState<(string | undefined)[]>([])
    const handleNewCardAdded = useCallback(async () => {
        const newPaymentOption = paymentOptions.data?.find(
            (po) => !originalCardIds.includes(po.id ?? "")
        )
        if (newPaymentOption?.id) {
            await putPreferredStoredPaymentMethod({
                storedPaymentMethodId: newPaymentOption.id,
            })
            void me.refresh()
        }
    }, [paymentOptions.data, originalCardIds, me])

    const [optionsForId, setOptionsForId] = useState<string | undefined>()
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [isError, setIsError] = useState(false)

    if (!orders.data?.orders.length && !paymentOptions.data?.length) return <></>

    return (
        <>
            <Heading level={3} margin={{ top: 32, bottom: 16 }}>
                {localize(props.heading)}
            </Heading>
            {!paymentOptions.data?.length && (
                <Box margin={{ bottom: "md" }}>
                    <Notification
                        text={localize(props.noPaymentMethods)}
                        icon="warning"
                        color="gray350"
                    />
                </Box>
            )}
            <Flex direction="column" gap={24}>
                {paymentOptions.data?.map((paymentOption) => (
                    <Flex
                        key={paymentOption.id}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            position: "relative",
                        }}
                        borderColor="gray200"
                        borderRadius="md"
                        padding="sm"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Flex
                            alignItems="center"
                            css={css({ gap: 16 }, responsiveCss("min", "lg", { gap: 24 }))}
                        >
                            {paymentOption.brand === "visa" ? (
                                <Visa />
                            ) : paymentOption.brand === "mc" ? (
                                <MasterCard />
                            ) : /applepay/.test(paymentOption.brand ?? "") ? (
                                <ApplePay />
                            ) : (
                                paymentOption.brand
                            )}
                            {me.data?.preferredStoredPaymentMethodId === paymentOption.id ? (
                                <LabelNotification color="gray500" size={["fixed", "xs", "md"]}>
                                    {localize(props.preferredLabel)}
                                </LabelNotification>
                            ) : null}
                        </Flex>
                        <Flex
                            alignItems="center"
                            style={{ fontVariantNumeric: "tabular-nums", position: "relative" }}
                            css={css({ gap: 16 }, responsiveCss("min", "lg", { gap: 24 }))}
                        >
                            <Flex alignItems="center" gap={8}>
                                <Flex alignItems="center">
                                    <Icon icon="asterisk" size={16} color="gray300" />
                                    <Icon icon="asterisk" size={16} color="gray300" />
                                    <Icon icon="asterisk" size={16} color="gray300" />
                                    <Icon icon="asterisk" size={16} color="gray300" />
                                </Flex>
                                <Text variant="body" size="md">
                                    {paymentOption.lastFour}
                                </Text>
                            </Flex>
                            <Text variant="body" size="md">
                                {paymentOption.expiryMonth}/{paymentOption.expiryYear}
                            </Text>
                            <div style={{ flexShrink: 0 }}>
                                <Button
                                    iconStart="more"
                                    size="xs"
                                    variant="secondary"
                                    disabled={
                                        me.data?.preferredStoredPaymentMethodId === paymentOption.id
                                    }
                                    onClick={() => setOptionsForId(paymentOption.id)}
                                />
                            </div>
                            {optionsForId === paymentOption.id && (
                                <Flex
                                    style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
                                    backgroundColor="grayWhite"
                                    css={css({ gap: 16 }, responsiveCss("min", "lg", { gap: 24 }), {
                                        "&:before": {
                                            content: '""',
                                            height: "100%",
                                            width: 48,
                                            background:
                                                "linear-gradient(270deg, rgba(255, 255,255,0), rgba(255, 255,255,1))",
                                        },
                                    })}
                                >
                                    <Button
                                        variant="light"
                                        size="xs"
                                        onClick={async () => {
                                            if (paymentOption.id) {
                                                await putPreferredStoredPaymentMethod({
                                                    storedPaymentMethodId: paymentOption.id,
                                                })
                                                void me.refresh()
                                                setOptionsForId(undefined)
                                            }
                                        }}
                                    >
                                        {localize(props.setAsPreferredButtonText)}
                                    </Button>
                                    <Button
                                        iconStart="close"
                                        size="xs"
                                        variant="secondary"
                                        onClick={() => setOptionsForId(undefined)}
                                        disabled={
                                            me.data?.preferredStoredPaymentMethodId ===
                                            paymentOption.id
                                        }
                                    />
                                </Flex>
                            )}
                        </Flex>
                    </Flex>
                ))}

                <Button
                    onClick={() => {
                        setAddCardId(Uuid().toString())
                        setModalIsOpen(true)
                    }}
                >
                    {localize(props.addPaymentMethods.modalTriggerButtonText)}
                </Button>
            </Flex>
            <Modal
                isOpen={modalIsOpen}
                width={640}
                onClose={() => setModalIsOpen(false)}
                header={{ title: localize(props.addPaymentMethods.modalTitle), closeButton: true }}
            >
                <Flex
                    direction="column"
                    gap={24}
                    css={css(
                        adyenPaymentFormCss,
                        css({ ".adyen-checkout__payment-method__header": { display: "none" } })
                    )}
                >
                    {props.addPaymentMethods.modalText ? (
                        <MarkdownView value={localize(props.addPaymentMethods.modalText)} />
                    ) : null}
                    {isError ? (
                        <>
                            <MarkdownView value={localize(props.addPaymentMethods.errorMessage)} />
                            <Button variant="dark" onClick={() => setIsError(false)}>
                                {localize({ no: "Prøv igjen", en: "Try again" })}
                            </Button>
                        </>
                    ) : (
                        <AdyenComponent
                            onSuccess={async () => {
                                setOriginalCardIds(paymentOptions.data?.map((po) => po.id) ?? [])
                                await paymentOptions.refresh()
                                void handleNewCardAdded()
                                setModalIsOpen(false)
                            }}
                            onError={() => setIsError(true)}
                            filterStoredPaymentMethods={() => false}
                            adyenOrder="add-payment-method"
                            adyenOrderId={addCardId}
                            translations={{
                                "no-NO": {
                                    "creditCard.holderName.placeholder": localize(
                                        props.addPaymentMethods.cardholderNamePlaceholder,
                                        LocaleKey.no
                                    ),
                                },
                                "en-US": {
                                    "creditCard.holderName.placeholder": localize(
                                        props.addPaymentMethods.cardholderNamePlaceholder,
                                        LocaleKey.en
                                    ),
                                },
                            }}
                            styles={{
                                base: {
                                    color: colors.gray500,
                                    fontWeight: "300",
                                    fontSize: "16px",
                                },
                                placeholder: {
                                    color: colors.gray300,
                                    fontWeight: "300",
                                    fontSize: "16px",
                                },
                            }}
                        />
                    )}
                </Flex>
            </Modal>
        </>
    )
}

function Visa(props: React.SVGAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={26}
            viewBox="0 0 40 26"
            fill="none"
            {...props}
        >
            <path fill="#fff" d="M0 0h40v26H0z" />
            <path
                fill="#1434CB"
                d="m15.9 7.7-4.43 10.6h-2.9l-2.2-8.47c-.13-.52-.25-.71-.65-.93C5.05 8.55 3.96 8.2 3 8l.07-.32h4.67c.6 0 1.13.4 1.27 1.09l1.15 6.14 2.86-7.23h2.89Zm11.39 7.15c0-2.8-3.88-2.96-3.85-4.21 0-.38.37-.79 1.16-.9a5.2 5.2 0 0 1 2.71.48l.48-2.25a7.4 7.4 0 0 0-2.57-.47c-2.71 0-4.62 1.44-4.64 3.51-.02 1.53 1.36 2.38 2.4 2.9 1.08.51 1.44.85 1.43 1.31 0 .71-.85 1.03-1.64 1.04-1.39.02-2.19-.37-2.82-.67l-.5 2.33c.64.29 1.82.55 3.05.56 2.89 0 4.78-1.42 4.79-3.63Zm7.17 3.46H37L34.78 7.7h-2.34c-.53 0-.98.3-1.17.78l-4.12 9.84h2.88l.57-1.58h3.53l.33 1.58Zm-3.07-3.76 1.45-3.99.83 4H31.4ZM19.83 7.7l-2.27 10.62h-2.74L17.09 7.7h2.74Z"
            />
        </svg>
    )
}

function MasterCard(props: React.SVGAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={26}
            viewBox="0 0 40 26"
            {...props}
        >
            <path fill="#fff" d="M0 0h40v26H0z" />
            <path fill="#F06022" d="M16.13 19.29h7.74V6.7h-7.74v12.58z" />
            <path
                fill="#EA1D25"
                d="M16.93 13A7.93 7.93 0 0 1 20 6.71a8.02 8.02 0 0 0-10.65.65 7.96 7.96 0 0 0 0 11.28 8.02 8.02 0 0 0 10.65.65A8.02 8.02 0 0 1 16.93 13"
            />
            <path
                fill="#F79D1D"
                d="M33 13c0 2.12-.84 4.15-2.34 5.65a8.1 8.1 0 0 1-10.66.64A8.05 8.05 0 0 0 23.07 13 7.96 7.96 0 0 0 20 6.71a8.02 8.02 0 0 1 10.66.64A7.93 7.93 0 0 1 33 13"
            />
        </svg>
    )
}

function ApplePay(props: React.SVGAttributes<SVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={40}
            height={26}
            viewBox="0 0 40 26"
            {...props}
        >
            <path
                fill="#000"
                d="M36.42 0H3.58a69.25 69.25 0 0 0-.75 0c-.25.01-.5.03-.76.07a2.51 2.51 0 0 0-1.32.7A2.43 2.43 0 0 0 .07 2.1 5.14 5.14 0 0 0 0 3.22v19.91c.01.25.03.51.07.76a2.6 2.6 0 0 0 .68 1.35 2.39 2.39 0 0 0 1.32.69 4.98 4.98 0 0 0 1.1.07h34a5 5 0 0 0 .76-.07 2.5 2.5 0 0 0 1.32-.7 2.44 2.44 0 0 0 .68-1.34 5.13 5.13 0 0 0 .07-1.11V2.87a6.5 6.5 0 0 0-.07-.76 2.58 2.58 0 0 0-.68-1.35 2.4 2.4 0 0 0-1.32-.69 4.96 4.96 0 0 0-1.1-.07h-.41Z"
            />
            <path
                fill="#fff"
                d="M36.42.87h.73c.2 0 .42.02.62.06a1.67 1.67 0 0 1 .88.44 1.58 1.58 0 0 1 .44.89 4.38 4.38 0 0 1 .06.97v19.55a14.67 14.67 0 0 1-.06.96 1.7 1.7 0 0 1-.44.89 1.54 1.54 0 0 1-.87.44 4.27 4.27 0 0 1-.96.06H2.85a3.7 3.7 0 0 1-.63-.06 1.66 1.66 0 0 1-.87-.45 1.56 1.56 0 0 1-.44-.88 4.35 4.35 0 0 1-.06-.97V2.9c.01-.2.02-.42.06-.63.03-.18.08-.34.16-.49A1.56 1.56 0 0 1 2.22.93a4.2 4.2 0 0 1 .96-.06h33.24"
            />
            <path
                fill="#000"
                d="M10.92 8.61c.34-.43.57-1 .51-1.59a2.21 2.21 0 0 0-1.99 2.3c.56.04 1.12-.3 1.48-.7Zm.51.81c-.82-.05-1.52.46-1.9.46-.4 0-1-.44-1.64-.42-.84 0-1.62.48-2.05 1.24-.88 1.52-.23 3.76.62 5 .42.6.92 1.27 1.58 1.25.62-.02.86-.4 1.62-.4.75 0 .97.4 1.63.39.69-.01 1.11-.61 1.53-1.22.47-.7.67-1.37.68-1.4-.01-.02-1.32-.52-1.33-2.02-.01-1.26 1.03-1.85 1.07-1.9a2.34 2.34 0 0 0-1.81-.98Zm7.11-1.7a2.87 2.87 0 0 1 3.02 3c0 1.8-1.27 3.03-3.06 3.03h-1.97v3.12h-1.42V7.72h3.43Zm-2 4.83h1.62c1.24 0 1.94-.66 1.94-1.82 0-1.15-.7-1.81-1.93-1.81h-1.64v3.63Zm5.39 2.43c0-1.17.9-1.89 2.48-1.98l1.83-.1v-.52c0-.74-.5-1.18-1.34-1.18-.8 0-1.3.38-1.41.97h-1.3c.08-1.2 1.1-2.09 2.76-2.09 1.62 0 2.65.86 2.65 2.2v4.6h-1.31v-1.1h-.04a2.38 2.38 0 0 1-2.1 1.2c-1.3 0-2.22-.8-2.22-2Zm4.3-.6v-.53l-1.64.1c-.82.06-1.28.42-1.28.99 0 .58.48.96 1.22.96.96 0 1.7-.66 1.7-1.52Zm2.61 4.95v-1.11c.1.03.33.03.44.03.64 0 .98-.27 1.19-.96l.12-.4-2.41-6.69h1.48l1.7 5.43h.02l1.69-5.43h1.44l-2.5 7.02c-.57 1.62-1.23 2.14-2.61 2.14-.12 0-.46-.01-.56-.03Z"
            />
        </svg>
    )
}
