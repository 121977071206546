import { Fragment, useEffect, useMemo, useState } from "react"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Localized } from "../../../../packages/localization/Localized"
import { useLocalizeSlug } from "../../../../packages/web/hooks/useLocalizeSlug"
import { Image, Markdown } from "../../../../reactor"
import { useWebPageInfo } from "../../../../studio/client"
import { Box } from "../ui/components/base/Box"
import { Flex } from "../ui/components/base/Flex"
import { Heading } from "../ui/components/typography/Heading"
import {
    css,
    responsiveCss,
    responsiveSectionSpacing,
    responsiveSpacing,
    scaleValue,
} from "../ui/helpers/css"
import { PromotedProductCta } from "./PromotedProducts"
import { ProductCard } from "../ui/components/cards/ProductCard"
import { Section } from "../../../../packages/editing/Section"
import {
    usePhoneColors,
    usePhoneOffers,
    usePhonesPriceHistogram,
    usePhoneStorageSizes,
} from "../client"
import { Checkbox } from "../ui/components/controllers/Checkbox"
import { TextInput } from "../ui/components/controllers/TextInput"
import { DualSlider } from "../ui/components/controllers/DualSlider"
import { Text } from "../ui/components/typography/Text"
import { colors } from "../ui/constants/colors"
import { Divider } from "../ui/components/other/Divider"
import { ResponsiveImage } from "../ui/components/base/ResponsiveImage"
import { Collapsible } from "../ui/components/base/Collapsible"
import { Icon } from "../ui/components/visual/Icon"
import { Dropdown } from "../ui/components/buttons/Dropdown"
import { Button } from "../ui/components/buttons/Button"
import { Modal } from "../ui/components/modal/Modal"
import { boxShadow } from "../ui/constants/shadow"
import { ProductDetailsSearchParams } from "./ProductDetails"

type SortOrder = "asc" | "desc"

Section(AllProducts)

/**
 * Section presenting all available products.
 */
function AllProducts(section: {
    /**
     * The heading for this section.
     * @default '{"no": "Alle enheter"}'
     */
    heading: Localized<string>

    /**
     * The call to action button on each product card. URL should point to general product page
     * and then the product id will be passed as parameter.
     */
    productCta: PromotedProductCta

    /**
     * A generic fallback image to show on a product card if the product does not have an image.
     */
    fallbackProductImage?: Image

    noMatches: {
        /**
         * @default '{"no": "Ingen treff"}'
         */
        heading: Localized<string>

        /**
         * @default '{"no": "Vi kunne ikke finne noen telefoner som matcher søket ditt."}'
         */
        text: Localized<Markdown>

        image?: Image
    }

    filters: {
        priceRange: {
            /**
             * @default '{"no": "Prisklasse", "en": "Price range"}'
             */
            heading: Localized<string>

            /**
             * @default '{"no": "Min", "en": "Min"}'
             */
            minLabel: Localized<string>

            /**
             * @default '{"no": "Maks", "en": "Max"}'
             */
            maxLabel: Localized<string>
        }
        storageSizes: {
            /**
             * @default '{"no": "Lagringsplass", "en": "Storage size"}'
             */
            heading: Localized<string>
        }
        colors: {
            /**
             * @default '{"no": "Farger", "en": "Colors"}'
             */
            heading: Localized<string>
        }
    }
}) {
    const localize = useLocalize()
    const productCtaPageInfo = useWebPageInfo(section.productCta.page)
    const localizeSlug = useLocalizeSlug()

    const allColors = usePhoneColors()
    const allSizes = usePhoneStorageSizes()
    const priceHistogram = usePhonesPriceHistogram()

    const [selectedSizes, setSelectedSizes] = useState<string[]>([])
    const [selectedColors, setSelectedColors] = useState<string[]>([])
    const [minPrice, setMinPrice] = useState<number | undefined>(
        priceHistogram.data?.min ?? undefined
    )
    const [maxPrice, setMaxPrice] = useState<number | undefined>(
        priceHistogram.data?.max ?? undefined
    )
    const [priceRangeCollsapsed, setPriceRangeCollsapsed] = useState(false)
    const [colorsCollsapsed, setColorsCollapsed] = useState(false)
    const [storageSizesCollapsed, setStorageSizesCollapsed] = useState(false)

    useEffect(() => {
        if (priceHistogram.data) {
            setMinPrice(priceHistogram.data.min)
            setMaxPrice(priceHistogram.data.max)
        }
    }, [priceHistogram.data])

    const rangeMin = priceHistogram.data?.min ?? 0
    const rangeMax = priceHistogram.data?.max ?? 1
    const range = rangeMax - rangeMin
    const left = ((minPrice ?? 0) - rangeMin) / range
    const right = ((maxPrice ?? 0) - rangeMin) / range
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

    const products = usePhoneOffers(undefined, selectedSizes, selectedColors, undefined)
    const filteredAndSortedProducts = useMemo(() => {
        const fp =
            products.data
                ?.filter(
                    (p) =>
                        p.monthlyPrice.itemsTotal.majorUnits.valueOf() >= (minPrice ?? 0) &&
                        p.monthlyPrice.itemsTotal.majorUnits.valueOf() <= (maxPrice ?? 1000000)
                )
                .sort((a, b) =>
                    (a.modelReleaseDate ? Date.parse(a.modelReleaseDate.valueOf()) : 0) >
                    (b.modelReleaseDate ? Date.parse(b.modelReleaseDate.valueOf()) : 0)
                        ? 1
                        : -1
                ) ?? []
        if (sortOrder === "asc") return fp.reverse()
        return fp
    }, [maxPrice, minPrice, sortOrder, products.data])
    const sortOrderOptions: { value: SortOrder; text: string }[] = [
        { value: "desc", text: localize({ no: "Nyeste først", en: "Newest first" }) },
        { value: "asc", text: localize({ no: "Eldste først", en: "Oldest first" }) },
    ]
    const [showFiltersModal, setShowFiltersModal] = useState(false)

    if (allColors.loading || allSizes.loading || priceHistogram.loading) return <></>

    const Filters = (
        <Flex
            direction="column"
            gap={24}
            css={css({
                gridColumn: "1",
            })}
        >
            <Flex direction="column" gap={24}>
                <Flex
                    justifyContent="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={() => setPriceRangeCollsapsed(!priceRangeCollsapsed)}
                >
                    <Text variant="heading" level="3">
                        {localize(section.filters.priceRange.heading)}
                    </Text>
                    <Icon
                        icon="chevronDown"
                        style={{
                            transform: `rotate(${priceRangeCollsapsed ? 180 : 0}deg)`,
                            transition: "all 200ms linear",
                        }}
                    />
                </Flex>
                <Collapsible collapsed={priceRangeCollsapsed}>
                    <Flex direction="column" gap={24}>
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: 102,
                                    alignItems: "flex-end",
                                    padding: "0 40px",
                                    marginBottom: 2,
                                    gap: 2,
                                    position: "relative",
                                    zIndex: 0,
                                }}
                            >
                                {priceHistogram.data?.bars.map((p, i) => (
                                    <div
                                        key={`${p.toString()}${i}`}
                                        style={{
                                            bottom: 0,
                                            height: p * 100 + "%",
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            backgroundColor: colors.brandLight,
                                            flex: 1,
                                        }}
                                        css={css`
                                            font-size: 500;
                                        `}
                                    />
                                ))}
                            </div>
                            <div
                                style={{
                                    marginTop: -14,
                                    position: "relative",
                                    zIndex: 10,
                                }}
                            >
                                <DualSlider
                                    left={left}
                                    setLeft={(newLeft) => setMinPrice(newLeft * range + rangeMin)}
                                    right={right}
                                    setRight={(newRight) =>
                                        setMaxPrice(newRight * range + rangeMin)
                                    }
                                />
                            </div>
                        </div>
                        <Flex gap={16}>
                            <div style={{ flex: 1 }}>
                                <TextInput
                                    label={localize(section.filters.priceRange.minLabel)}
                                    value={Math.round(minPrice ?? 0).toString()}
                                />
                            </div>
                            <div style={{ flex: 1 }}>
                                <TextInput
                                    label={localize(section.filters.priceRange.maxLabel)}
                                    value={Math.round(maxPrice ?? 0).toString()}
                                />
                            </div>
                        </Flex>
                    </Flex>
                </Collapsible>
            </Flex>
            <Divider horizontal spacing={0} />
            <div>
                <Flex direction="column">
                    <Flex
                        justifyContent="space-between"
                        style={{ cursor: "pointer" }}
                        onClick={() => setColorsCollapsed(!colorsCollsapsed)}
                    >
                        <Text variant="heading" level="3" margin={{ bottom: 24 }}>
                            {localize(section.filters.colors.heading)}
                        </Text>
                        <Icon
                            icon="chevronDown"
                            style={{
                                transform: `rotate(${colorsCollsapsed ? 180 : 0}deg)`,
                                transition: "all 200ms linear",
                            }}
                        />
                    </Flex>
                    <Collapsible collapsed={colorsCollsapsed}>
                        {allColors.data?.map((c) => (
                            <Checkbox
                                key={`${c.name}`}
                                padding={scaleValue(20)}
                                label={
                                    <Flex gap={16}>
                                        <div
                                            style={{
                                                flexShrink: 0,
                                                backgroundColor: c.color,
                                                height: 24,
                                                width: 24,
                                                borderRadius: 24,
                                            }}
                                        />
                                        {localize(c.displayName)}
                                    </Flex>
                                }
                                checked={selectedColors.includes(c.name)}
                                onChange={(v) =>
                                    setSelectedColors(
                                        v.target.checked
                                            ? [...selectedColors, c.name]
                                            : selectedColors.filter((id) => id !== c.name)
                                    )
                                }
                            />
                        ))}
                    </Collapsible>
                </Flex>
            </div>
            <Divider horizontal spacing={0} />
            <div>
                <Flex
                    justifyContent="space-between"
                    style={{ cursor: "pointer" }}
                    onClick={() => setStorageSizesCollapsed(!storageSizesCollapsed)}
                >
                    <Text variant="heading" level="3" margin={{ bottom: 24 }}>
                        {localize(section.filters.storageSizes.heading)}
                    </Text>
                    <Icon
                        icon="chevronDown"
                        style={{
                            transform: `rotate(${storageSizesCollapsed ? 180 : 0}deg)`,
                            transition: "all 200ms linear",
                        }}
                    />
                </Flex>
                <Collapsible collapsed={storageSizesCollapsed}>
                    {allSizes.data?.map((s) => (
                        <Checkbox
                            key={s.name}
                            label={s.displayName}
                            checked={selectedSizes.includes(s.name)}
                            onChange={(v) =>
                                setSelectedSizes(
                                    v.target.checked
                                        ? [...selectedSizes, s.name]
                                        : selectedSizes.filter((id) => id !== s.name)
                                )
                            }
                            padding={scaleValue(20)}
                        />
                    ))}
                </Collapsible>
            </div>
        </Flex>
    )

    if (!productCtaPageInfo.data?.slug) return <></>

    return (
        <div css={css(responsiveSectionSpacing())}>
            <Flex direction="column">
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    css={css(
                        { marginBottom: 16 },
                        responsiveCss("min", "md", { marginBottom: 40 })
                    )}
                >
                    <Heading level={2}>{localize(section.heading)}</Heading>
                    <Flex gap={16} alignItems="center">
                        <Text
                            variant="body"
                            size="md"
                            css={responsiveCss("max", "md", { display: "none" })}
                        >
                            {localize({ no: "Vis", en: "Show" })}
                        </Text>
                        <Dropdown
                            value={sortOrder}
                            options={sortOrderOptions}
                            onChange={(e, val) => setSortOrder(val)}
                        />
                    </Flex>
                </Flex>
                <div
                    css={css({ marginBottom: 16 }, responsiveCss("min", "md", { display: "none" }))}
                >
                    <Divider horizontal spacing="sm" />
                    <Button
                        iconStart="filter"
                        variant="light"
                        css={css({ boxShadow })}
                        fullwidth
                        onClick={() => setShowFiltersModal(true)}
                    >
                        {localize({ no: "Filter", en: "Filter" })}
                    </Button>
                    <Modal
                        isOpen={showFiltersModal}
                        onClose={() => setShowFiltersModal(false)}
                        header={{
                            icon: "filter",
                            closeButton: true,
                            title: localize({ no: "Filter", en: "Filter" }),
                            divider: true,
                        }}
                        width="100%"
                    >
                        {Filters}
                    </Modal>
                </div>
                <div
                    css={css(
                        {
                            display: "grid",
                        },
                        responsiveCss("max", "sm", {
                            gridAutoFlow: "row",
                            gridTemplateRows: "1fr",
                        }),
                        responsiveCss("min", "md", {
                            gridTemplateColumns: "1fr 1fr 1fr",
                            alignItems: "start",
                        }),
                        responsiveSpacing("sm", "gap")
                    )}
                >
                    <div css={responsiveCss("max", "sm", { display: "none" })}>{Filters}</div>
                    <div
                        css={css(
                            { display: "grid" },
                            responsiveCss("max", "sm", {
                                gridAutoFlow: "row",
                                gridTemplateRows: "1fr",
                            }),
                            responsiveCss("min", "md", {
                                gridTemplateColumns: "1fr 1fr",
                                gridColumn: "2 / 4",
                                gridAutoRows: "1fr",
                            }),
                            responsiveSpacing("sm", "gap")
                        )}
                    >
                        {!filteredAndSortedProducts.length ? (
                            <Flex
                                gap={32}
                                direction="column"
                                style={{ width: "100%", gridColumn: " span 2" }}
                            >
                                <Flex
                                    backgroundColor="gray100"
                                    justifyContent="center"
                                    borderRadius="md"
                                    padding={64}
                                >
                                    {section.noMatches.image ? (
                                        <ResponsiveImage
                                            image={section.noMatches.image}
                                            width={400}
                                        />
                                    ) : null}
                                    <Flex
                                        gap={16}
                                        alignItems="center"
                                        justifyContent="center"
                                        direction="column"
                                    >
                                        <Heading level={2}>
                                            {localize(section.noMatches.heading)}
                                        </Heading>
                                        <Text variant="body" size="lg">
                                            {localize(section.noMatches.text)}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        ) : undefined}
                        {filteredAndSortedProducts.map((p, i) => (
                            <Fragment key={p.id.valueOf()}>
                                <Box
                                    css={css(
                                        responsiveCss("max", "sm", {
                                            display: "none",
                                        })
                                    )}
                                >
                                    <ProductCard
                                        alwaysShowCta={section.productCta.alwaysShow}
                                        name={localize(p.name)}
                                        features={p.features?.map((f) => localize(f.text)) ?? []}
                                        price={localize({
                                            no: `Fra ${p.monthlyPrice.itemsTotal.majorUnits.toString()} kr/md`,
                                            en: `From ${p.monthlyPrice.itemsTotal.majorUnits.toString()} NOK/mo`,
                                        })}
                                        // highlightPrice={p.highlightPrice}
                                        image={p.images?.[0]}
                                        cta={{
                                            text: localize(section.productCta.text),
                                            url: `${localizeSlug(productCtaPageInfo.data?.slug)}?${ProductDetailsSearchParams({ phoneId: p.id })}`,
                                        }}
                                        relativePhysicalSize={p.relativePhysicalSize?.valueOf()}
                                    />
                                </Box>
                                <Flex
                                    direction="column"
                                    gap={16}
                                    css={responsiveCss("min", "md", {
                                        display: "none",
                                    })}
                                >
                                    <ProductCard
                                        alwaysShowCta={section.productCta.alwaysShow}
                                        name={localize(p.name)}
                                        features={p.features?.map((f) => localize(f.text)) ?? []}
                                        price={localize({
                                            no: `Fra ${p.monthlyPrice.itemsTotal.majorUnits.toString()} kr/md`,
                                            en: `From ${p.monthlyPrice.itemsTotal.majorUnits.toString()} NOK/mo`,
                                        })}
                                        // highlightPrice={p.highlightPrice}
                                        image={p.images?.[0]}
                                        cta={{
                                            text: localize(section.productCta.text),
                                            url: `${localizeSlug(productCtaPageInfo.data?.slug)}?${ProductDetailsSearchParams({ phoneId: p.id })}`,
                                        }}
                                        relativePhysicalSize={p.relativePhysicalSize?.valueOf()}
                                        compact
                                    />
                                </Flex>
                            </Fragment>
                        ))}
                    </div>
                </div>
            </Flex>
        </div>
    )
}
