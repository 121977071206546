import { useEffect, useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { css } from "@emotion/react"
import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import { Section } from "../../../../packages/editing/Section"
import { useInsuranceOptions } from "../client"
import { useLocalize } from "../../../../packages/localization/client-side/useLocalize"
import { Heading } from "../ui/components/typography/Heading"
import { Markdown, Uuid } from "../../../../reactor"
import {
    useCheckoutOrder,
    useCheckoutShippingState,
    useCheckoutTradeInForm,
    useCheckoutTradeInPriceRequest,
    useCheckoutTradeInResult,
    usePhoneOfferOptions,
    useResetPhoneOffer,
} from "./Checkout"
import { Flex } from "../ui/components/base/Flex"
import { Image } from "../../../../reactor"
import { Localized } from "../../../../packages/localization/Localized"
import { responsiveBorderRadius, responsiveCss, responsiveSectionSpacing } from "../ui/helpers/css"
import { MustacheString } from "../../../../packages/editing/Mustache"
import { CheckoutStepSummaryBlock } from "../ui/components/blocks/CheckoutStepSummaryBlock"
import { ResponsiveImage } from "../ui/components/base/ResponsiveImage"
import { Page } from "../../model/Page"
import { useWebPageInfo } from "../../../../studio/client"
import { Text } from "../ui/components/typography/Text"
import { Button } from "../ui/components/buttons/Button"
import { MarkdownView } from "../ui/components/base/MarkdownView"
import { monthsLong, monthsShort, useFormatAmount } from "../ui/constants/text"
import { Divider } from "../ui/components/other/Divider"

/**
 * Declares the global `fbq` function for Meta Pixel.
 */
declare const fbq: undefined | ((eventType: string, eventName: string, ...args: any[]) => void)
/**
 * Declare dataLayer used by Google Tag Manager.
 */
declare const dataLayer: undefined | any[]

Section(OrderConfirmation)
function OrderConfirmation(section: {
    /**
     * Image presented at the top of the page
     */
    topImage?: Image

    /**
     * Page heading.
     * @default '{"no": "Din ordre er bekreftet"}'
     */
    heading: Localized<string>

    /**
     * Text below heading.
     * @default '{"no": "Gratulerer, din første ordre er nå gjennomført og sendes snart fra vårt lager."}'
     */
    subheading: Localized<Markdown>

    monthlyTotalPrice: {
        /**
         * @default '{"no": "Fast månedspris"}'
         */
        heading: Localized<string>

        /**
         * @default '{"no": "{{price}}/{{monthsShort}}"}'
         */
        price: Localized<string>

        /**
         * Available variables are {{rentalPeriod}}, {{monthsShort}}, {{monthlyPrice}}, and {{totalPrice}}.
         * @default '{"no": "Gjelder valgt periode på {{rentalPeriod}} måneder."}'
         */
        text: Localized<string>
    }

    /**
     * Order summary.
     */
    summary: {
        /**
         * @default '{"en": "Summary", "no": "Oppsummering"}'
         */
        heading: Localized<string>

        device: {
            /**
             * @default '{"no": "Telefon", "en": "Phone"}'
             */
            stepName?: Localized<string>

            /**
             * @default '{"no": "Totalpris for telefonen etter {{rentalPeriod}} {{monthsLong}} er {{totalPrice}}"}'
             */
            rentalPeriodTotalPrice: Localized<string>
        }

        tradeIn: {
            /**
             * @default '{"no": "Innbytte"}'
             */
            stepName: Localized<string>

            /**
             * @default '{"en": "We will deduct {{monthlyDiscount}} for the next {{discountPeriod}} months", "no": "Vi trekker fra {{monthlyDiscount}} på regningen din de neste {{discountPeriod}} månedene"}'
             */
            text: Localized<string>

            /**
             * @default '[{"id": "%UUID%", "text": {"no": "Du vil motta en bekreftelses-e-post med gratis fraktetikett."}}, {"id": "%UUID%", "text": {"no": "Vi kontrollerer mobilen og sier fra om ev. justeringer i verdi."}}, {"id": "%UUID%", "text": {"no": "Din månedlige kostnad reduseres allerede fra første betaling"}}]'
             */
            list?: {
                readonly id: Uuid
                /**
                 * @title
                 */
                text: Localized<string>
            }[]
        }

        insurance: {
            /**
             * @default '{"no": "Trygghetspakke"}'
             */
            stepName: Localized<string>
        }

        shipping: {
            /**
             * @default '{"no": "Levering"}'
             */
            stepName: Localized<string>

            /**
             * @default '{"no": "Leveres {{deliveryDate}} mellom {{deliverySlotFrom}} og {{deliverySlotTo}}"}'
             */
            deliverySlotText: Localized<string>

            /**
             * Available variables are `{{deliveryOptionName}}` and `{{deliveryOptionDescription}}`
             * @default '{"no": "{{deliveryOptionDescription}}"}'
             */
            otherDeliveryOptionText?: Localized<string>
        }

        /**
         * Image presented in the order summary.
         */
        image?: Image
    }

    /**
     * Config for error screen that is presented to the user if the order id in the URL does not
     * match the order in the session. This should not happen unless the URL is modified by the
     * user.
     */
    orderMismatchError: OrderMismatchProps
}) {
    const localize = useLocalize()
    const formatAmount = useFormatAmount()
    const [phone] = useResetPhoneOffer()
    const [options] = usePhoneOfferOptions()
    const [searchParams] = useSearchParams()
    const { order } = useCheckoutOrder()
    const [checkoutShippingState] = useCheckoutShippingState()
    const [checkoutTradeInPriceRequest] = useCheckoutTradeInPriceRequest()
    const [checkoutTradeInForm] = useCheckoutTradeInForm()
    const [checkoutTradeInResult] = useCheckoutTradeInResult()

    dayjs.extend(advancedFormat)

    useEffect(
        () => {
            if (typeof fbq !== "undefined") {
                fbq("track", "Purchase", {
                    content_ids: [phone?.id.valueOf()],
                    content_name: phone?.name ? localize(phone.name) : undefined,
                    content_type: "product",
                    value: phone?.monthlyPrice.itemsTotal.majorUnits,
                    currency: "NOK",
                })
            }
            // Check of `dataLayer` (global array used by Google Tag Manager to track events)
            // exists before trying to push the event. If it does, push the conversion event
            // including Google Ads reference.
            if (typeof dataLayer !== "undefined" && dataLayer instanceof Array) {
                dataLayer.push({
                    event: "conversion",
                    send_to: "AW-16797113852/fQOfCKbFq-8ZEPy7vsk-",
                    value: phone?.monthlyPrice.itemsTotal.majorUnits,
                    currency: "NOK",
                    transaction_id: "",
                })
            }
        },
        // Should only trigger once, when the component mounts
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    const insuranceOptions = useInsuranceOptions(
        order?.model.id ?? null,
        order?.phone.storage ?? null,
        order?.phone.color ?? null,
        order?.rentalPeriod.months ?? null
    )
    const selectedInsurance = useMemo(
        () => insuranceOptions.data?.find((insurance) => insurance.id === order?.insurance),
        [insuranceOptions.data, order?.insurance]
    )

    if (!phone || !options || !order) {
        return <></>
    }

    const orderParam = searchParams.get("o")

    if (order.id.valueOf() !== orderParam) {
        return <OrderMismatch {...section.orderMismatchError} />
    }

    return (
        <Flex justifyContent="center" css={css(responsiveSectionSpacing())}>
            <Flex direction="column" gap={24} style={{ minWidth: 0, maxWidth: 560 }}>
                <Flex direction="column" gap={24}>
                    {section.topImage ? (
                        <div
                            css={css(
                                { paddingLeft: 12, paddingRight: 12 },
                                responsiveCss("min", "sm", { paddingLeft: 32, paddingRight: 32 }),
                                responsiveCss("min", "md", { paddingLeft: 48, paddingRight: 48 })
                            )}
                        >
                            <ResponsiveImage
                                image={section.topImage}
                                width={560}
                                style={{ width: "100%" }}
                            />
                        </div>
                    ) : null}
                    <Flex direction="column" gap={12}>
                        <Heading level={2}>{localize(section.heading)}</Heading>
                        <Heading level={3}>{localize(section.subheading)}</Heading>
                    </Flex>
                    <Flex
                        borderColor="gray200"
                        borderRadius="md"
                        padding="md"
                        direction="column"
                        gap={8}
                    >
                        <Flex justifyContent="space-between" alignItems="flex-start">
                            <div>
                                <Text variant="heading" level="4">
                                    {localize(section.monthlyTotalPrice.heading)}
                                </Text>
                                <Flex alignItems="baseline" gap={2}>
                                    <Text variant="heading" level="3" color="brand">
                                        {formatAmount(order.rentalPeriod.monthlyPrice.itemsTotal)}/
                                        {localize(monthsShort)}
                                    </Text>
                                </Flex>
                            </div>
                        </Flex>
                        <Text variant="body" size="sm" color="gray300">
                            {MustacheString(localize(section.monthlyTotalPrice.text), {
                                rentalPeriod: `${order.rentalPeriod.months.valueOf()}`,
                                monthsShort: `${localize(monthsShort)}`,
                                monthlyPrice: formatAmount(
                                    order.rentalPeriod.monthlyPrice.itemsTotal
                                ),
                                totalPrice: `${formatAmount({ minorUnits: order.rentalPeriod.monthlyPrice.phone.minorUnits * order.rentalPeriod.months.valueOf(), majorUnits: order.rentalPeriod.monthlyPrice.phone.majorUnits * order.rentalPeriod.months.valueOf(), currency: order.rentalPeriod.monthlyPrice.phone.currency })}`,
                            })}
                        </Text>
                    </Flex>
                </Flex>
                <Divider horizontal spacing={0} />
                <Flex direction="column" gap={24}>
                    <Heading level={3}>{localize(section.summary.heading)}</Heading>
                    <CheckoutStepSummaryBlock
                        icon="device"
                        step={localize(section.summary.device.stepName ?? {})}
                        title={localize(order.model.name)}
                        price={`${formatAmount(order.rentalPeriod.monthlyPrice.phone)}/${localize(monthsShort)}`}
                        subtitle={MustacheString(
                            localize(section.summary.device.rentalPeriodTotalPrice),
                            {
                                rentalPeriod: `${order.rentalPeriod.months.valueOf()}`,
                                monthsShort: `${localize(monthsShort)}`,
                                monthsLong: `${localize(monthsLong)}`,
                                totalPrice: `${formatAmount({ minorUnits: order.rentalPeriod.monthlyPrice.phone.minorUnits * order.rentalPeriod.months.valueOf(), majorUnits: order.rentalPeriod.monthlyPrice.phone.majorUnits * order.rentalPeriod.months.valueOf(), currency: order.rentalPeriod.monthlyPrice.phone.currency })}`,
                            }
                        )}
                    />
                    {checkoutTradeInForm &&
                    checkoutTradeInPriceRequest &&
                    typeof checkoutTradeInResult?.value !== "undefined" &&
                    order.tradeIn &&
                    checkoutTradeInResult.deviceName ? (
                        <CheckoutStepSummaryBlock
                            icon="handCoins"
                            step={localize(section.summary.tradeIn.stepName)}
                            title={localize(checkoutTradeInResult.deviceName)}
                            subtitle={MustacheString(localize(section.summary.tradeIn.text), {
                                monthlyDiscount: formatAmount(
                                    checkoutTradeInResult.monthlyDiscount
                                ),
                                discountPeriod: `${checkoutTradeInResult.months.valueOf()}`,
                            })}
                            price={formatAmount(checkoutTradeInResult.value)}
                            list={section.summary.tradeIn.list?.map((li) => ({
                                id: li.id.valueOf(),
                                text: localize(li.text),
                            }))}
                        />
                    ) : null}
                    {selectedInsurance && order.rentalPeriod.monthlyPrice.insurance ? (
                        <CheckoutStepSummaryBlock
                            icon="shield"
                            step={localize(section.summary.insurance.stepName)}
                            title={localize(selectedInsurance.displayName)}
                            price={`${formatAmount(order.rentalPeriod.monthlyPrice.insurance)}/${localize(monthsShort)}`}
                            list={selectedInsurance.sellingPoints.map((isp) => ({
                                id: isp.id.valueOf(),
                                text: localize(isp.text),
                            }))}
                        />
                    ) : null}
                    {checkoutShippingState.selectedOption ? (
                        <CheckoutStepSummaryBlock
                            icon="truck"
                            step={localize(section.summary.shipping.stepName)}
                            title={localize(checkoutShippingState.selectedOption.name)}
                            subtitle={
                                checkoutShippingState.selectedOption.type === "Manual" &&
                                checkoutShippingState.selectedOption.selectedSlot
                                    ? MustacheString(
                                          localize(section.summary.shipping.deliverySlotText),
                                          {
                                              deliveryDate: dayjs(
                                                  checkoutShippingState.selectedOption.selectedSlot
                                                      .date
                                              ).format("dddd Do MMMM"),
                                              deliverySlotFrom:
                                                  checkoutShippingState.selectedOption.selectedSlot
                                                      .from,
                                              deliverySlotTo:
                                                  checkoutShippingState.selectedOption.selectedSlot
                                                      .to,
                                          }
                                      )
                                    : section.summary.shipping.otherDeliveryOptionText
                                      ? MustacheString(
                                            localize(
                                                section.summary.shipping.otherDeliveryOptionText
                                            ),
                                            {
                                                deliveryOptionName: localize(
                                                    checkoutShippingState.selectedOption.name
                                                ),
                                                deliveryOptionDescription: localize(
                                                    checkoutShippingState.selectedOption.description
                                                ),
                                            }
                                        )
                                      : localize(checkoutShippingState.selectedOption.description)
                            }
                            price={
                                order.shippingCost.majorUnits.valueOf() > 0
                                    ? formatAmount(order.shippingCost)
                                    : localize({ no: "Gratis", en: "Free" })
                            }
                            text={[
                                checkoutShippingState.form?.street.value,
                                `${checkoutShippingState.form?.postalCode.value} ${checkoutShippingState.form?.region.value}`,
                            ]
                                .filter((t) => !!t)
                                .join(", ")}
                        />
                    ) : null}
                    {section.summary.image ? (
                        <ResponsiveImage
                            image={section.summary.image}
                            width={600}
                            css={responsiveBorderRadius("md")}
                        />
                    ) : null}
                </Flex>
            </Flex>
        </Flex>
    )
}

type OrderMismatchProps = {
    /**
     * @default '{"no": "Feil i ordrenummer."}'
     */
    heading: Localized<string>

    /**
     * @default '{"no": "Vi kunne ikke finne denne ordrebekreftelsen. Det betyr ikke at ordren ikke finnes. Hvis det er en ordre du nettopp har gjennomført skal du kunne finne den på din profil."}'
     */
    text: Localized<Markdown>

    primaryAction: {
        /**
         * @default '{"no": "Gå til min profil."}'
         */
        buttonText: Localized<string>

        /**
         * The page to go to when clicking the primary button.
         */
        page: Page["id"]
    }
}

export function OrderMismatch(props: OrderMismatchProps) {
    const localize = useLocalize()
    const primaryActionPageInfo = useWebPageInfo(props.primaryAction.page)

    return (
        <Flex
            justifyContent="center"
            alignItems="center"
            style={{ width: "100%", height: "100%", padding: "80px 0" }}
        >
            <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                css={css(responsiveCss("max", "sm", { padding: "16px 0" }), { maxWidth: 600 })}
            >
                <Flex
                    direction="column"
                    alignItems="center"
                    gap={12}
                    css={css(
                        responsiveCss("max", "sm", { gap: 8, marginBottom: 32 }),
                        responsiveCss("min", "md", {
                            padding: "0 40px",
                            gap: 12,
                            marginBottom: 40,
                        })
                    )}
                >
                    <Heading color="gray500" level={2}>
                        {localize(props.heading)}
                    </Heading>
                    <Text color="gray400" variant="body" size="md" style={{ textAlign: "center" }}>
                        <MarkdownView value={localize(props.text)} />
                    </Text>
                </Flex>
                {primaryActionPageInfo.data?.slug ? (
                    <Button
                        variant="primary"
                        size="md"
                        href={`/${localize(primaryActionPageInfo.data.slug)}`}
                    >
                        {localize(props.primaryAction.buttonText)}
                    </Button>
                ) : null}
            </Flex>
        </Flex>
    )
}
